.congratulationContainer {
  display: flex   ;
  flex-shrink: 0 ;
  flex: 1 100% ;
  height: 56.25rem ;
  .logo {
    margin: 1.625rem 4.625rem ;
    width: 4.438rem ;
    height: 4.25rem ;
    position: absolute ;
  }
  .sideImage {
    position: absolute ;
    width: 281px ;
    height: 403px ;
    bottom: 0 ;
  }
  .congratulations {
    display: flex ;
    margin: auto ;
    margin-top: 3.75rem ;
    .centerCircle {
      margin-top: 60px ;
      // flex-flow: column;
      // text-align: center;
      img {
        margin: auto;
        background: transparent;
      }
      h1 {
        // margin-top: 0.75rem;
        // text-align: center;
        //styleName: Heading/H1;
        font-family: Inter ;
        font-size: 48px ;
        font-weight: 700 ;
        line-height: 67.2px ;
        text-align: center ;
        text-underline-position: from-font ;
        text-decoration-skip-ink: none ;
      }
      .helpingText {
        //styleName: Body/Body 1;
        font-family: Inter ;
        font-size: 18px ;
        font-weight: 400 ;
        line-height: 25.2px ;
        text-align: center ;
        text-underline-position: from-font ;
        text-decoration-skip-ink: none ;
      }
      // span {
      //   margin-top: 1.5rem;
      //   text-align: center;
      // }
      // button {
      //   @include button;
      //   align-self: center;
      //   width: 50%;
      //   margin-top: 2.75rem;
      // }
    }
  }
}
