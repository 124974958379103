@import "./selectLoginType.scss";
@import "./congratulations.scss";

.psAuthorization {
  flex-flow: row;
  display: flex;
  justify-content: center;
  background: $surface-dark-five-color;

  .leftSection {
    display: flex;
    .background {
      width: 100%;
      height: 100%;
      // width: 50rem;
      // height: 56.25rem;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    .logo {
      margin: 1.625rem;
      width: 4.438rem;
      height: 4.25rem;
      position: absolute;
    }
  }

  .rightSection {
    // width: 40rem;
    width: 100%;
    display: flex;
    // align-items: center;
    // justify-content: center;
    // margin: 10%;
    // margin-top: 162px;
    .MuiBox-root {
      width: 100%;
      margin-left: 6.25rem;
      margin-top: 5.25rem;
      @include selectLoginType();
    }
  }

  // .loginContainer {
  //   display: flex;
  //   flex-direction: row;
  //   align-content: center;
  //   align-self: center;
  //   height: auto;
  //   border-radius: 1rem;
  //   padding-bottom: 1rem;

  //   .goBackButton {
  //     display: flex;
  //     vertical-align: middle;
  //     height: 1.5rem;
  //     margin-bottom: 0.875rem;
  //     img {
  //       width: 1.5rem;
  //       height: 1.5rem;
  //     }
  //     span {
  //       margin: auto;
  //       margin-left: 0.5rem;
  //       color: $action-secondary-color;
  //       font-size: 14px;
  //       font-weight: 400;
  //     }
  //   }

  //   .h1 {
  //     margin: 0 0 1rem;
  //     text-align: left;
  //   }

  //   .leftSection {
  //     display: flex;
  //     flex-direction: unset;
  //     width: 55%;
  //     height: 100%;

  //     .background {
  //       width: 50rem;
  //       align-items: self-end;
  //       height: 56.25rem;
  //       aspect-ratio: 16/9;
  //       object-fit: cover;
  //     }

  //     .logo {
  //       margin: 1.625rem;
  //       width: 4.438rem;
  //       height: 4.25rem;
  //       position: absolute;
  //     }
  //   }

  //   .rightSection {
  //     width: 40rem;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin: 10%;

  //     .elementContainer {
  //       max-width: 438px;
  //       gap: 2.5rem;
  //       width: 100%;
  //       height: 29.938rem;
  //       margin: auto;
  //       align-self: center;

  // @include selectLoginType();
  //       @include loginByEmail();
  //       @include loginByPhoneNumber();
  //       @include loginByPhoneNumber();
  //       @include createAccount();
  //     }
  //   }
  // }
}
/* 
@media (max-width: 1024px) {
  .psAuthorization {
    .loginContainer {
      flex-direction: column;

      // height: 45%;
      .infoContainer {
        flex-direction: column;
        width: 100%;
        align-items: center;

        h1 {
          width: 90%;
        }
      }

      .elementContainer {
        padding: 0 0.5rem 0 0.5rem;

        .form {
          width: 100%;
        }

        .bottomContainer {
          flex-flow: column-reverse;

          .push {
            width: 100%;
            height: 3rem;
            margin-bottom: 0.5rem;
          }
        }

        .regBottomContainer {
          button {
            width: 100%;
            height: 3rem;
          }
        }
      }
    }
  }
} */

/* @media (max-width: 768px) {

  // margin-bottom: 3rem;
  .psAuthorization {
    overflow-y: scroll;

    .loginContainer {
      flex-direction: column;
      // height: auto;
      width: 80%;
      margin-bottom: 3rem;

      .infoContainer {
        flex-direction: column;
        width: 100%;
        align-items: center;

        h1 {
          width: 90%;
        }
      }

      .elementContainer {
        padding: 0 0.5rem 0 0.5rem;

        .form {
          width: 100%;

          .termsCondition {
            flex-flow: column;
          }
        }

        .bottomContainer {
          flex-flow: column-reverse;
          margin-bottom: 1rem;

          .push {
            width: 100%;
            height: 3rem;
            margin-bottom: 0.5rem;
          }

        }
      }
    }
  }
} */
