@import "./congratulations.scss";
@import "./stepsContainer.scss";
@import "./stepsBar.scss";
@import "./personalDetails.scss";
@import "./schoolDetails.scss";
.registrationContainer {
  display: flex;
  flex-shrink: 0;
  flex: 1 100%;
  .logo {
    margin: 1.625rem 4.625rem;
    width: 4.438rem;
    height: 4.25rem;
    position: absolute;
  }
  .sideImage {
    position: absolute;
    width: 281px;
    height: 403px;
    top: 29rem;
  }
  @include congratulations;
  @include stepsContainer;
  @include stepsBar;
  @include personalDetails;
  @include schoolDetails;
}
