.hover {
  .orange,
  &.orange {
    &:hover {
      opacity: 90%;
      cursor: pointer;
      border: 1px solid $btn-hover-borderColor;
      background: $btn-hover-background;
    }
  }
}

.hoverOrange {
  &:hover {
    opacity: 90%;
    cursor: pointer;
    border: 1px solid $btn-hover-borderColor;
    background: $btn-hover-background;
  }
}

.f-14 {
  font-size: 14px;
}

.fw-400 {
  font-weight: 400;
}
.ml-8 {
  margin-left: 8px;
}
.psHide {
  visibility: hidden;
}
.cursorPointer {
  cursor: pointer;
}

.textStrike {
  text-decoration: line-through;
}
