@mixin personalDetails {
  .personalDetails {
    display: flex;
    flex-flow: column;
    // width: 59.25rem;
    .content {
      height: 100%;
      .element {
        height: 90%;
      }
    }
  }
}
