@mixin congratulations {
  .congratulations {
    display: flex;
    margin: auto;
    margin-top: 3.75rem;
    .centerCircle {
      img {
        margin: auto;
        background: transparent;
      }
    }
  }
}
