@mixin stepsBar {
  .stepsBar {
    position: relative;
    width: 59.25rem;
    .stepsRectangle {
      margin-top: 50px;
      position: absolute;
      left: 0;
      width: 100%;
    }
    .stepCount {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      img {
        left: 1rem;
        margin-top: 2.2rem;
        z-index: 1;
      }
    }
    .contents {
      width: 984px;
    }
  }
}

/* Rectangle 23927 */

// position: absolute;
// width: 4px;
// height: 788px;
// left: 85px;
// top: 50px;

// background: #2A2A2A;
// transform: rotate(-90deg);

/* Group 238025 */

// position: absolute;
// width: 32px;
// height: 32px;
// left: 63px;
// top: 32px;

// /* Frame 1321316478 */

// /* Auto layout */
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
// padding: 3px 6px;
// gap: 10px;

// position: absolute;
// width: 24px;
// height: 24px;
// left: 67px;
// top: 36px;

// background: #F8F8F8;
// border-radius: 12px;

// /* 1 */

// width: 7px;
// height: 20px;

// /* Heading/H8 */
// font-family: 'Inter';
// font-style: normal;
// font-weight: 700;
// font-size: 14px;
// line-height: 140%;
// /* identical to box height, or 20px */

// color: #1C1C1C;

// /* Inside auto layout */
// flex: none;
// order: 0;
// flex-grow: 0;

// /* Ellipse 1546 */

// box-sizing: border-box;

// position: absolute;
// width: 32px;
// height: 32px;
// left: 63px;
// top: 32px;

// border: 2px solid #F8F8F8;
