/** Select login type*/

@mixin selectLoginType {
  max-width: 27.375rem;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    font-family: Inter;
    line-height: 4.2rem;
    text-align: left;
  }

  .btnSelectType {
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
  }

  .btnUserType {
    width: 7.65rem;
    //styleName: Body/Body 3;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
  }

  .orSeparator {
    span {
      align-items: center;
      hr {
        width: 45%;
        float: left;
        height: 0px;
        border-bottom: 0;

        &:nth-last-child(2) {
          float: right;
        }
      }
    }
  }

  .socialMedia {
    gap: 0;
    display: flex;
    flex-flow: row;
    width: 18.125rem;
    margin: auto;
    margin-top: 2.5rem;
    div {
      margin: auto;
      display: flex;
      width: 4.375rem;
      height: 4.375rem;
      background-color: $surface-dark-two-color;
      vertical-align: middle;

      img {
        margin: auto;
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
/** END Select login type*/
