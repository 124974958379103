.goBackButton {
  display: flex;
  vertical-align: middle;
  height: 1.5rem;
  margin-bottom: 0.875rem;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  span {
    margin: auto;
    margin-left: 0.5rem;
    color: $action-secondary-color;
    font-size: 0.875rem;
    font-weight: 400;
  }
}
