@mixin schoolDetails {
  .schoolDetails {
    display: flex;
    flex-flow: column;
    // width: 59.25rem;
    // background-color: aqua;
    // height: 33.625rem;
    .content {
      height: 100%;
      .element {
        height: 90%;
      }
    }
  }
}
