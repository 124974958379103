.studentSideMenu {
  .logo {
    margin: 1.5rem auto 1.5rem 0;
  }
  .logo20 {
    width: 24px;
    height: 24px;
  }

  .avatar {
    width: 4.06rem;
    height: 4.06rem;
    margin-bottom: 0.5rem;
  }

  .avatar24 {
    width: 24px;
    height: 24px;
  }

  .logout {
    // margin-left: 0.5rem;
  }

  .icon24 {
    width: "1.5rem";
    height: "1.5rem";
  }
  .MuiListItem-root {
    &:hover {
      background-color: #1c2631;
      background: #1c2631;
      color: #3f7df9;
    }
    .MuiListItemIcon-root {
      &:hover {
        color: #3f7df9;
      }
    }
  }
}
