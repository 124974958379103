@import "./font.scss";
@import "./colorCodes.scss";
// @import "./bodies.scss";
// @import "./headings.scss";
// @import "./buttons.scss";
@import "./common/goBackButton.scss";
@import "./container.scss";
// @import "./judgeDashboard.scss";
@import "./authorization/authorization.scss";
@import "./authorization/registrationSteps/registrationContainer";
@import "./common.scss";

.mjx-math {
  font-size: 12px !important;
  line-height: 1 !important;
  white-space: pre-line !important;
  overflow-wrap: break-word !important;
  text-align: start !important;
}
.MJXc-TeX-main-R {
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
}
.mjx-block {
  display: flex !important;
}
.MJXc-TeX-math-I {
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
}
// .css-hhdjsd-MuiContainer-root{
//   margin: 0 !important;
//   max-width :100% !important;
//   padding-left: 0 !important;
//   padding-right: 0 !important;
// }

// .css-1ail04w {
//   padding: 2px 2px !important;
// }

// .css-1v13922-MuiToolbar-root{
//   padding: 2px 0px !important;
// }
// .css-19erpe0 {
//   padding: 2px 20px !important;
// }

// .css-1lt5qva-MuiCardContent-root:last-child{
//   padding: 10px 0px !important;
// }

// .scroll-container-vertical::-webkit-scrollbar {
//   width: 8px;
//   /* height: 5px; */
//   border-radius: 25px;
// }

// .scroll-container-vertical::-webkit-scrollbar-track {
//   background: #ddd;
//   border-radius: 25px;
// }

// .scroll-container-vertical::-webkit-scrollbar-thumb {
//   border-radius: 25px;
//   background: #9b9a9a;
// }

// .scroll-container-vertical::-webkit-scrollbar-thumb:hover {
//   border-radius: 25px;
//   background: #696969;
// }

// .scroll-container-vertical::-webkit-scrollbar-button {
//   border-radius: 25px;
//   display: none;
// }
