@mixin stepsContainer {
  .stepsContainer {
    display: flex;
    margin: 0 auto;
    text-align: center;
    flex-flow: column;
    width: 948px;
    margin-top: 3.125rem;
    .stepsContainer {
      margin: auto;
    }
  }
}
